<template>
  <div class="view-font-size">
    <a-row type="flex">
      <a-col :span="21">
        <a-slider v-model="zoom" :min="100" :max="120" :tooltip-visible="false" />
      </a-col>
      <a-col :span="3" class="current-zoom">
        <p>{{ currentZoom }}</p>
      </a-col>
    </a-row>
  </div>
</template>
<script>
export default {
  name: "Zoom",
  created() {
    const app = document.getElementById("app");
    const zoom = this.$store.state.zoom;
    app.style = `zoom: ${zoom}%;`;
  },
  data() {
    return {
      zoom: this.$store.state.zoom,
    };
  },
  computed: {
    currentZoom() {
      return this.zoom - 100;
    },
  },
  watch: {
    zoom: {
      handler() {
        const app = document.getElementById("app");
        this.$store.commit("change_zoom", this.zoom);
        app.style = `zoom: ${this.zoom}%;`;
      },
    },
  },
};
</script>
