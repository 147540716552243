<template>
  <div class="settings">
    <div class="title"><span>Ajustes</span></div>
    <a-row type="flex" justify="space-between">
      <a-col :span="7" class="settings-container">
        <span>Tamaño de texto</span>
        <Zoom />
      </a-col>
      <a-col :span="7" class="settings-container">
        <span>Iluminación</span>
        <a-row type="flex" class="settings-dark-mode">
          <a-col :span="12">
            <span>Modo oscuro</span>
          </a-col>
          <a-col class="dark-mode-switch">
            <a-switch v-model="switchDarkMode" @change="darkModeChange" />
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="7" class="settings-container">
        <span>Soporte</span>
        <a-row type="flex" class="settings-support">
          <a-col :span="12">
            <span>{{ getSupportPhone }}</span>
          </a-col>
          <a-col class="support-call product-stock">
            <p>Llamar</p>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row type="flex" justify="space-between" class="second-line">
      <a-col :span="7" class="settings-container">
        <span>Sonido a utilizar</span>
        <a-radio-group v-model="sound" @change="appSoundChange">
          <a-radio value="1"> Campana </a-radio>
          <a-radio value="0"> Teléfono </a-radio>
          <a-radio value="2"> Silenciosa </a-radio>
        </a-radio-group>
      </a-col>
    </a-row>
    <div class="footer">
      {{ getVersion }}
    </div>
  </div>
</template>
<script>
import Zoom from "@/components/settings/Zoom";
const audio1 = new Audio(require("../../assets/new_order.mp3"));
const audio0 = new Audio(require("../../assets/new_order_2.mp3"));
export default {
  name: "Settings",
  components: {
    Zoom,
  },
  data() {
    return {
      switchDarkMode: false,
      sound: 1,
    };
  },
  created() {
    this.switchDarkMode = this.$store.state.darkMode;
    this.sound = this.$store.state.appSound;
    this.darkModeChange(this.switchDarkMode);
  },
  methods: {
    appSoundChange() {
      this.$store.commit("set_appSound", this.sound);
      if (this.sound == 1) {
        audio1.play();
      } else if (this.sound == 0) {
        audio0.play();
      }
    },
    darkModeChange(value) {
      this.$store.commit("set_darkMode", value);
      if (value) {
        document.body.classList.add("dark-mode");
      } else {
        document.body.classList.remove("dark-mode");
      }
    },
  },
  computed: {
    getVersion() {
      const version = this.$store.getters.appVersion;
      return `v ${version} CoralSoft`;
    },
    getSupportPhone() {
      const support = this.$store.state.support;
      return support != "" ? support.person.phone : "";
    }
  },
};
</script>
